<script setup>
    const onClick = () => {
        // Open Hubspot chat widget (works only on production)
        window.HubSpotConversations.widget.open();

        // GTM Click - CTA - open hubpot chat button
        dataLayer.push({
            event: 'generic_event',
            event_name: 'click_open_hubspot_chat',
            button_id: 'open_hubspot_chat',
            button_name: 'open_hubspot_chat',
        });
    }
</script>

<template>
    <PrimevueButton
        label="Chat"
        icon="ic:baseline-chat"
        severity="secondary"
        class="w-full"
        outlined
        @click="onClick"
    />
</template>
